import React, { useContext } from 'react'
import { languages } from '../../data/other'
import { GlobalContext } from '../../store/GlobalStore'

import { Dropdown } from 'semantic-ui-react'

const LanguageSelect = () => {
    const { setActiveLanguage, activeLanguage } = useContext(GlobalContext);

    const handleClick = (e, d) => {
        e.preventDefault()
        localStorage.setItem('activeLanguage', d.value)
        setActiveLanguage(d.value)
    }

    return (
      <Dropdown
        defaultValue={activeLanguage}
        selection
        compact
        icon='langselect'
        className='langselect'
        options={languages}
        onChange={handleClick}
      />
    )
}

export default LanguageSelect