import React from 'react'

import useGlobalContext from '../../hooks/useGlobalContext'
import { i18n } from '../../data/other'

const Footer = () => {

    const { activeLanguage } = useGlobalContext()

    return (
    <footer className="copyright">
        {i18n['Шаблон сайта взят у'][activeLanguage] + ' '} <a href='https://mldangelo.com/' target='_blank' rel="noreferrer">MICHAEL D'ANGELO</a>
    </footer>
    )
}

export default Footer;