import React from 'react'
import { Link } from 'react-router-dom'

import Hamburger from './Hamburger'
import routes from '../../data/routes'
import LanguageSelect from '../LanguageSelect'
import 'semantic-ui-css/semantic.min.css'

import { i18n } from '../../data/other'
import useGlobalContext from '../../hooks/useGlobalContext'

// Websites Navbar, displays routes defined in 'src/data/routes'
const Navigation = () => {

  const { activeLanguage } = useGlobalContext()

  return (
    <header id="header">
      <h1 className="index-link">
        {routes.filter((l) => l.index).map((l) => (
          <Link key={l.label} to={l.path}>{i18n[l.label][activeLanguage]}</Link>
        ))}
      </h1>
      <nav className="links">
        <ul>
          {routes.filter((l) => !l.index).map((l) => (
            <li key={l.label}>
              <Link to={l.path}>{i18n[l.label][activeLanguage]}</Link>
            </li>
          ))}
        </ul>
      </nav>
      <LanguageSelect />
      <Hamburger />
    </header>
  )
}

export default Navigation;
