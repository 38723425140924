import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';
import Footer from './Footer'

import useGlobalContext from '../../hooks/useGlobalContext'
import { i18n } from '../../data/other'

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => {

  const { activeLanguage } = useGlobalContext()

  return (
    <section id="sidebar">
      <section id="intro">
        <Link to="/" className="logo">
          <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
        </Link>
        <header>
          <h2>{i18n['Александр Макаров'][activeLanguage]}</h2>
          <p><a href="mailto:makarov.alex.d@gmail.com">makarov.alex.d@gmail.com</a></p>
        </header>
      </section>

      <section className="blurb">
        <h2>{i18n['Обо мне'][activeLanguage]}</h2>
        <p>{i18n['sideBarText'][activeLanguage]}
        </p>
        <ul className="actions">
          <li>
            {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">{i18n['Узнать больше'][activeLanguage]}</Link> : <Link to="/" className="button">{i18n['Обо мне'][activeLanguage]}</Link>}
          </li>
        </ul>
      </section>

      <section id="footer">
        <ContactIcons />
        <Footer />
      </section>
    </section>
  )
}

export default SideBar;
