const LangReducer = (state, action) => {
   switch(action.type) {
       case 'SET_ACTIVE_LANGUAGE':
           return {
                ...state,
                activeLanguage: action.payload
           }
       default:
           return state;
   }
}

export default LangReducer