import React, { createContext, useReducer } from 'react';
import LangReducer from '../reducers/LangReducer';

const getActiveLanguage = () => {

  //check for local storage
  const lsLang = localStorage.getItem('activeLanguage')

  if(lsLang){
    return lsLang
  }

  //check for browserLang

  const browserLang = navigator.language

  if(/ru/.test(browserLang)){
    return 'ru'
  }

  if(/en/.test(browserLang)){
    return 'us'
  }

  //else
  return 'ru'

}

const initialState = {
  activeLanguage: getActiveLanguage()
}



export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
   const [state, dispatch] = useReducer(LangReducer, initialState);

   // Actions for changing state

   function setActiveLanguage(item) {
       dispatch({
           type: 'SET_ACTIVE_LANGUAGE',
           payload: item
       });
   }

   return(
      <GlobalContext.Provider value = {{...state, setActiveLanguage}}> 
        {children} 
      </GlobalContext.Provider>
   )
}

