export const  languages = [
        { key: 'ru', value: 'ru', flag: 'ru', text: 'Русский' },
        { key: 'us', value: 'us', flag: 'us', text: 'English' },
    ]

export const i18n = {

        'Обо мне': {
          'ru': 'Обо мне',
          'us': 'About me'
        },
      
        'Узнай про Александра Макарова': {
          'ru': 'Узнай про Александра Макарова',
          'us': 'Learn about Alexander Makarov'
        },
      
        'в': {
          'ru': 'в',
          'us': 'in'
        },
      
        'словах': {
          'ru': 'словах',
          'us': 'words'
        },
      
        'Александр Макаров': {
          'ru': 'Александр Макаров',
          'us': 'Alexander Makarov'
        },

        'Персональный сайт Александра Макарова': {
          'ru': 'Персональный сайт Александра Макарова',
          'us': 'Personal website of Alexander Makarov'
        },

        'Свяжитесь с Александром Макаровым по почте': {
          'ru': 'Свяжитесь с Александром Макаровым по почте',
          'us': 'Contact Alexander Makariv via email'
        },

        'Контакты': {
          'ru': 'Контакты',
          'us': 'Contacts'
        },

        'Буду рад пообщаться по Вашим проектам. Пишите на почту': {
          'ru': 'Буду рад пообщаться по Вашим проектам. Пишите на почту',
          'us': 'I would be very pleased to communicate with you about your projects. Feel free to write to me'
        },

        'Страница не найдена': {
          'ru': 'Страница не найдена',
          'us': 'Page not found'
        },

        'Возвратиться на': {
          'ru': 'Возвратиться на',
          'us': 'Return back to'
        },

        'главную страницу': {
          'ru': 'главную страницу',
          'us': 'the main page'
        },

        'Узнайте про проекты Александра Макарова': {
          'ru': 'Узнайте про проекты Александра Макарова',
          'us': "Learn about Alexander's projects"
        },

        'Проекты': {
          'ru': 'Проекты',
          'us': 'Projects'
        },

        'Список моих проектов': {
          'ru': 'Список моих проектов',
          'us': 'List of projects'
        },

        'Образование': {
          'ru': 'Образование',
          'us': 'Education'
        },

        'Опыт работы': {
          'ru': 'Опыт работы',
          'us': 'Experience'
        },

        'Навыки': {
          'ru': 'Навыки',
          'us': 'Skills'
        },

        'Курсы': {
          'ru': 'Курсы',
          'us': 'Courses'
        },

        'Резюме': {
          'ru': 'Резюме',
          'us': 'Resume'
        },

        'Резюме Александра Макарова': {
          'ru': 'Резюме Александра Макарова',
          'us': 'Resume of Alexander Makarov'
        },

        'Все': {
          'ru': 'Все',
          'us': 'All'
        },

        'Разработка': {
          'ru': 'Разработка',
          'us': 'IT'
        },

        'Финансовые': {
          'ru': 'Финансовые',
          'us': 'Finance'
        },

        'Финансовое моделирование (FICC, FX, CCY свопы)': {
          'ru': 'Финансовое моделирование (FICC, FX, CCY свопы)',
          'us': 'Financial modeling (FICC, FX, CCY swaps)'
        },

        'Денежный рынок': {
          'ru': 'Денежный рынок',
          'us': 'Money Market'
        },

        'Оценка стоимости бизнеса': {
          'ru': 'Оценка стоимости бизнеса',
          'us': 'Business Valuation'
        },

        'ПО и сервисы': {
          'ru': 'ПО и сервисы',
          'us': 'Software & Services'
        },

        'Прочие': {
          'ru': 'Прочие',
          'us': 'Other'
        },

        'Языки': {
          'ru': 'Языки',
          'us': 'Languages'
        },

        'Библиотеки': {
          'ru': 'Библиотеки',
          'us': 'Libraries'
        },

        'Шаблон сайта взят у': {
          'ru': 'Шаблон сайта взят у',
          'us': 'Website template taken from'
        },


        'sideBarText': {
          'ru': 'Привет! Меня зовут Александр. Мне нравятся финансы, IT и общение с интересными людьми. Рад видеть тебя на моем персональном сайте!',
          'us': 'Hi! My name is Alexander. I love finance, IT, and talking to interesting people. Welcome to my personal webpage!'
        },
        
        'Бакалавр. Финансовый менеджмент': {
          'ru': 'Бакалавр. Финансовый менеджмент',
          'us': 'BSc, Financial management'
        },

        'РЭУ им. Г.В. Плеханова': {
          'ru': 'РЭУ им. Г.В. Плеханова',
          'us': 'REU of G.V. Plekhanov'
        },

        'Узнать больше': {
          'ru': 'Узнать больше',
          'us': 'Learn more'
        }
    }