import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Navigation from '../components/Template/Navigation';
import SideBar from '../components/Template/SideBar';
import ScrollToTop from '../components/Template/ScrollToTop';
import useGlobalContext from '../hooks/useGlobalContext'

import { i18n } from '../data/other'

const Main = (props) => {

  const { activeLanguage } = useGlobalContext()

  return (
    <HelmetProvider>
      <ScrollToTop />
      <Helmet titleTemplate={`%s | ${i18n['Александр Макаров'][activeLanguage]}`} defaultTitle={i18n['Александр Макаров'][activeLanguage]} defer={false}>
        {props.title && <title>{props.title}</title>}
        <meta name="description" content={props.description || i18n['Персональный сайт Александра Макарова'][activeLanguage]} />
      </Helmet>
      <div id="wrapper">
        <Navigation />
        <div id="main">
          {props.children}
        </div>
        {props.fullPage ? null : <SideBar />}
      </div>
  
    </HelmetProvider>
  )
}

export default Main;
