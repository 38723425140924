const routes = [
  {
    index: true,
    label: "Александр Макаров",
    path: '/',
  },
  {
    label: 'Резюме',
    path: '/resume',
  },
  {
    label: 'Проекты',
    path: '/projects',
  },
  {
    label: 'Контакты',
    path: '/contact',
  },
];

export default routes;
